<!--
 * @Date: 2023-11-28 16:39:59
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2023-12-04 17:04:06
 * @FilePath: \zyt-zlb-mobile-develop\src\views\cinemaAssistant\competitorCinema\attendanceDetail.vue
-->
<template>
  <div>
    <div>
      <van-cell-group>
        <van-field
          label="当前影院: "
          :custom-style="{ color: 'red' }"
          style="color: aliceblue"
          :value="ourCinema.cinemaName"
          readonly
        />
        <!-- 选中竞对影院 -->
        <van-field
          label="竞对影院: "
          placeholder="请选择竞对影院"
          custom-style="color: aliceblue;"
          clickable
          readonly
          right-icon="arrow-down"
          :value="selectedCompetitorCinema.cinemaName"
          @click-input="handleCompetitorCinemaClick"
        >
        </van-field>
      </van-cell-group>
    </div>
    <div>
      <ve-table
        :scroll-width="0"
        :table-data="tableData"
        :max-height="maxHeight"
        :fixed-header="true"
        :show-header="true"
        :highlight-current-row="true"
        :columns="columns"
        v-if="tableData.length > 0"
      ></ve-table>
      <div v-else style="text-align: center; margin-top: 20px">
        <van-empty description="暂无数据" />
      </div>
    </div>
    <van-popup
      v-model="showCompetitorCinema"
      round
      position="center"
      :style="{ width: '90%', height: '50%' }"
      @click-overlay="onClose"
    >
      <van-picker
        show-toolbar
        title="请选择竞对影院"
        value-key="cinemaName"
        :columns="competitorCinemaList"
        @cancel="onClose"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
export default {
  data() {
    return {
      // 表格最大高度
      maxHeight: 1000,
      showCompetitorCinema: false,
      ourCinema: null,
      cinemaName: '',
      // 竞对影院列表
      competitorCinemaList: [],
      // 选中的竞对影院
      selectedCompetitorCinema: {},
      tableData: [],
      columns: [
        //序号
        {
          title: '序号',
          field: 'index',
          key: 'index',
          width: 50,
          fixed: 'left',
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            const text = row[column.field]

            return <span>{rowIndex + 1}</span>
          },
        },
        {
          title: '影片',
          field: 'filmName',
          key: 'filmName',
          width: 150,
          fixed: 'left',
        },
        {
          title: '昨天',
          field: 'yesterday',
          children: [
            {
              key: 'yesterday-our',
              field: 'yesterday',
              title: '当前影院',
              width: 80,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                // console.log({ row, column, rowIndex })
                return (
                  <div>
                    <div>
                      <span>{row.yesterday.our.toFixed(2)}万</span>
                    </div>
                  </div>
                )
              },
            },
            {
              key: 'yesterday-competitor',
              field: 'yesterday',
              title: '竞对影院',
              width: 80,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.yesterday.competitor.toFixed(2)}万</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
        {
          title: '近7天',
          field: 'before7Days',
          children: [
            {
              key: 'before7Days-our',
              field: 'yestebefore7Daysrday',
              title: '当前影院',
              width: 80,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                // console.log({ row, column, rowIndex })
                return (
                  <div>
                    <div>
                      <span>{row.before7Days.our.toFixed(2)}万</span>
                    </div>
                  </div>
                )
              },
            },
            {
              key: 'before7Days-competitor',
              field: 'before7Days',
              title: '竞对影院',
              width: 80,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.before7Days.competitor.toFixed(2)}万</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
        {
          title: '近30天',
          field: 'before30Days',
          children: [
            {
              key: 'before30Days-our',
              field: 'before30Days',
              title: '当前影院',
              width: 80,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                // console.log({ row, column, rowIndex })
                return (
                  <div>
                    <div>
                      <span>{row.before30Days.our.toFixed(2)}万</span>
                    </div>
                  </div>
                )
              },
            },
            {
              key: 'before30Days-competitor',
              field: 'before30Days',
              title: '竞对影院',
              width: 80,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <div>
                      <span>{row.before30Days.competitor.toFixed(2)}万</span>
                    </div>
                  </div>
                )
              },
            },
          ],
        },
      ],
    }
  },
  mounted() {
    // 动态计算表格最大高度
    this.maxHeight = window.innerHeight - 10
    // 获取当前影院名称
    this.ourCinema = JSON.parse(this.$route.query.ourCinema)
    // 填充竞对影院列表 并且默认选中第一个
    this.getCompetitorCinema().then((res) => {
      console.log({ res })
      this.selectedCompetitorCinema = res[0]
      this.getCompetitorSeatRate()
    })
  },
  methods: {
    // 获取竞对影院
    getCompetitorCinema() {
      console.log(this.$route.query)
      const query = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.$route.query.id,
      }
      console.log('获取竞对影院')
      //设置页面标题
      return new Promise((resolve, reject) => {
        zjApi
          .getCompetitorList(query)
          .then((res) => {
            console.log({ res })
            this.competitorCinemaList = res
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 获取影院与竞对影院上座率详情
    getCompetitorSeatRate() {
      const query = {
        UserCode: this.$store.state.userInfo.mobile,
        ourCinemaId: this.ourCinema.id,
        competitorCinemaId: this.selectedCompetitorCinema.id,
      }
      console.log('获取影院与竞对影院上座率详情')
      zjApi
        .getAnalysisSeatRateInfo(query)
        .then((res) => {
          console.log({ res })
          this.tableData = res
        })
        .catch((err) => {
          console.log({ err })
        })
    },
    /**
     * @description: 选中竞对影院
     * @return {*}
     */
    handleCompetitorCinemaClick() {
      console.log('选中竞对影院')
      this.showCompetitorCinema = true
      console.log(
        { showCompetitorCinema: this.showCompetitorCinema },
        '执行竞对查询',
      )
    },
    // 关闭弹窗
    onClose() {
      this.showCompetitorCinema = false
    },
    // 确认选择
    onConfirm(value, index) {
      this.selectedCompetitorCinema = value
      this.showCompetitorCinema = false
      this.getCompetitorSeatRate()
    },
  },
}
</script>
